import React from 'react';
import Main from './Main';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql/queries';
import { useLanguage } from './hooks/useLanguage';
function App() {
  const { language, updateLanguage } = useLanguage();

  

  return (
    <ApolloProvider client={client}>
    <Main language={language} setLanguage={updateLanguage} />
    </ApolloProvider>
  );
}

export default App;
