import React from 'react';
import { FiThumbsUp } from 'react-icons/fi';
import { BsHouse } from 'react-icons/bs';
import { TbAward } from 'react-icons/tb';
import { BsGraphUpArrow } from 'react-icons/bs';
import jsonData from '../data/main-page.json'
import { lang } from '../types/language';

interface Props {
    language: lang
}
function ServicesSection({ language }: Props) {
    const servicesData = jsonData.data['services-section'];
    return (
        <section className='relative services-section md:px-32 flex flex-col justify-center'>
            <div className="absolute inset-0 bg-black opacity-70"></div>
            <div className='relative z-10 w-full grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 justify-between text-white'>
                {
                    servicesData.map((svc, index) => (
                        <div className='p-5 grid grid-cols-1 text-center' key={`${index}-${svc.en.title}`}>
                            <span className='font-bold text-lg base-alert md:text-xl'>
                                {svc[language].title}
                            </span>
                            <span>
                                {svc[language].content}
                            </span>
                            <div className='flex justify-center mt-4'>
                                {index === 1 ? (
                                    <FiThumbsUp className='text-white font-thin' fontSize={40} />
                                ) : index === 2 ? (
                                    <TbAward className='text-white' fontSize={40} />
                                ) : index === 3 ? (
                                    <BsGraphUpArrow className='text-white' fontSize={40} />
                                ) : (
                                    <BsHouse className='text-white' fontSize={40} />
                                )}
                            </div>
                        </div>
                    ))
                }
            </div>

        </section>
    )
}

export default ServicesSection;
