import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/font.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { StoreProvider } from 'easy-peasy';
import store from './state/store';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <StoreProvider store={store}>
        <App />
      </StoreProvider>
    </React.StrictMode>
  </HelmetProvider>
);

reportWebVitals();
