import React from 'react';
import Navigation from './components/navigation';
import SearchSection from './components/search';
import ImageCarousel from './components/image-carousel';
import SEO from './components/SEO';
import PropertiesSection from './components/properties-section';
import img_1 from './assets/image-1-770x460.jpeg';
import img_2 from './assets/image-2-770x460.jpeg';
import img_3 from './assets/image-3-770x460.jpeg';
import helmetCardImage from './/assets//helmet-image-card.png';
import { lang } from './types/language';
import ServicesSection from './components/services-section';

interface Props {
  language: lang;
  setLanguage: (language: lang) => void;
}
function Main({language, setLanguage}: Props) {
  const [images] = React.useState([img_1,img_2,img_3])
  const [helmetImage] = React.useState(helmetCardImage)


  return (
    <div  className=' flex flex-col w-full'>
      <SEO
        title="Inmobiliaria Yucatan Paraiso"
        description="A real estate agency in Yucatan, Mexico."
        image={helmetImage}
        url={window.location.href}
        keywords='real estate, yucatan, mexico, merida, yucatan paraiso, inmobiliaria yucatan paraiso'
      />
      <Navigation language={language} setLanguage={setLanguage} />
      <ImageCarousel images={images} />
      <SearchSection />
      <PropertiesSection language={language} />
      <ServicesSection  language={language}/>
    
    </div>
  );
}

export default Main;

