import React from 'react';
import { Property } from '../types/property';
import { LiaSquareSolid } from 'react-icons/lia';
import { LuBed } from 'react-icons/lu';
import { PiBathtub } from 'react-icons/pi';
import { TbRulerMeasure } from 'react-icons/tb';
import { lang } from '../types/language';
import { useFormatNumberWithCurrency } from '../hooks/useFormatNumberWithCurrency';
import { useStoreState } from '../state/store';
interface PropertyCardProps {
    property: Property;
    expandableCard?: boolean;
    language: lang;
}
export function PropertyCard({ property, expandableCard = false, language }: PropertyCardProps) {
    const { title, mediaCollection } = property;
    const singleCard = !expandableCard;
    if (expandableCard) {
        return (
            <>
                <PropertyDetails property={property} language={language} />
                <div className="col-span-1">
                    <PropertyImage url={mediaCollection.items[0].url} title={title} />
                </div>
            </>
        )
    } else {
        return (
            <>
                <PropertyDetails property={property} singleCard={singleCard} language={language} />
            </>
        )
    }
}


export function PropertyPriceTag({ price, currencyType, currencyTypeTextClass }: { price: number; currencyType: string, currencyTypeTextClass: string }) {
    const formattedCurrency = useFormatNumberWithCurrency(price);
    return (
        <div className='relative'>
            <span className={`absolute -top-6 right-0 p-1 text-xs ${currencyTypeTextClass} font-bold`}>
                {currencyType}
            </span>
            <span className='bg-alert p-2 text-white font-medium'>
                {formattedCurrency}
            </span>
        </div>
    );
}


export function PropertyHighlitDetails({ squareMeters, bedroom, bathroom }: { squareMeters: number, bedroom: number, bathroom: number }) {
    return (
        <div className='flex'>
            <span className='flex items-end mr-4'> <TbRulerMeasure size={25} /> {squareMeters.toLocaleString()} m&sup2;</span>
            <span className='flex items-end mr-4'><LuBed size={25} className='mr-2' /> {bedroom}</span>
            <span className='flex items-end mr-4'><PiBathtub size={25} className='mr-2' /> {bathroom}</span>
        </div>
    )
}
interface PropertyDetailsProps {
    property: Property;
    singleCard?: boolean;
    language: lang;
}

export function PropertyDetails({ property, singleCard, language }: PropertyDetailsProps) {
    const currency = useStoreState(state => state.currency);
    const { price, squareMeters, bedroom, bathroom, mediaCollection: { items } } = property;
    const mainImage = items[0].url;
    if (singleCard) {
        return (
            <div className="col-span-1 flex flex-col justify-between bg-white pb-5 rounded">
                <div className=' relative w-full'>
                    <div className='absolute  bottom-2 left-0'>
                        <PropertyPriceTag price={price} currencyType={currency} currencyTypeTextClass='text-white' />
                    </div>
                    <PropertyImage url={mainImage} title={selectTitleByLanguage(language, property)} height={300} mt={0} />
                </div>
                <div className='mt-5 px-1'>
                    <span className='base-alert'>{selectPropertyTypeByLanguage(language, property)} </span>
                    <h1 className=' text-2xl md:text-3xl'>{selectTitleByLanguage(language, property)}</h1>
                    <hr className="border-t-1 border-gray-300 mt-5" />
                    <div className=' mt-4 flex items-end justify-start'>
                        <PropertyHighlitDetails squareMeters={squareMeters} bedroom={bedroom} bathroom={bathroom} />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="col-span-1 flex flex-col justify-around">
            <h1 className=' text-3xl md:text-5xl'>{selectTitleByLanguage(language, property)}</h1>
            <p className=' text-xl mt-4 font-medium'>{selectDescriptionByLanguage(language, property)}</p>

            <ul className='grid grid-cols-2 gap-4 mt-4'>
                {
                    selectHighlightByLanguage(language, property).map((highlight: string, index: number) => (
                        <li className='text-sm md:text-lg font-medium col-span-1 flex items-center'
                            key={index}> <LiaSquareSolid color='#ef595a' /> {highlight}</li>
                    ))
                }
            </ul>
            <div className=' mt-4 flex justify-around'>
                <PropertyPriceTag price={price} currencyType={currency} currencyTypeTextClass='text-red-600' />
                <PropertyHighlitDetails squareMeters={squareMeters} bedroom={bedroom} bathroom={bathroom} />
            </div>
        </div>
    )
}

export function PropertyImage({ url, title, height, mt = 5 }: { url: string, title: string, height?: number, mt?: number }) {
    return (
        <img src={url} alt={title} className={`mt-${mt}  w-full`} style={{ maxHeight: height }} />
    )
}



function selectTitleByLanguage(language: lang, property: Property) {
    switch (language) {
        case 'en':
            return property.titleEn;
        case 'fr':
            return property.titleFr;
        default:
            return property.titleEs;
    }
}

function selectDescriptionByLanguage(language: lang, property: Property) {
    switch (language) {
        case 'en':
            return property.descriptionEn;
        case 'fr':
            return property.descriptionFr;
        default:
            return property.descriptionEs;
    }
}

function selectHighlightByLanguage(language: lang, property: Property): Property['highlightsEn'] | Property['highlightsFr'] | Property['highlightsEs'] {
    switch (language) {
        case 'en':
            return property.highlightsEn;
        case 'fr':
            return property.highlightsFr;
        default:
            return property.highlightsEs;
    }
}

function selectPropertyTypeByLanguage(language: lang, property: Property) {
    switch (language) {
        case 'en':
            return property.propertyTypeEn;
        case 'fr':
            return property.propertyTypeFr;
        default:
            return property.propertyTypeEs;
    }
}