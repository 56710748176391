import {  useEffect } from 'react';
import { useStoreActions, useStoreState } from '../state/store'
import { Currency } from '../types/currency';

export const useCurrency = ()=> {
    const currency = useStoreState(state => state.currency);
    const setCurrency = useStoreActions(actions => actions.setCurrency);
    // const [currency, setCurrency] = useState<Currency>('USD');

    useEffect(()=> {
        const currency = localStorage.getItem('currency');
        if(currency) {
            setCurrency(currency as Currency);
        }
    }, [setCurrency]);

    const changeCurrency = (currency: Currency) => {
        setCurrency(currency);
        localStorage.setItem('currency', currency);
    }

    return {currency, changeCurrency};
}