import React from 'react';
import logo from '../assets/logo.png'
import { BiLocationPlus } from 'react-icons/bi'
import { HiOutlineMail } from 'react-icons/hi'
import { FiPhoneCall } from 'react-icons/fi'
import { lang } from '../types/language';
import Select from './select';
import { useCurrency } from '../hooks/useCurrency';
import { Currency } from '../types/currency';
interface Props {
    language: lang;
    setLanguage: (language: lang) => void;
}
function Navigation({ language, setLanguage }: Props) {
    const { currency, changeCurrency } = useCurrency();
    return (
        <header className='header-nav  h-screen md:h-64 z-10 absolute w-full'>
            <nav className='layover-bg-1 flex flex-col justify-center p-5 md:flex-row md:items-center'>
                <img src={logo} alt="logo" className=' w-auto md:w-96 rounded-lg' />
                <div className='flex flex-col w-full font-medium'>
                    <ul className='flex  justify-between md:flex-row md:items-center md:justify-between w-full text-white px-5 pb-5'>
                        <li className='items-center hidden md:flex'>
                            <BiLocationPlus className='text-4xl  base-yellow' /> <span className=' text-3xl'>Yucatan</span>
                        </li>
                        <li className='items-center hidden md:flex'>
                            <HiOutlineMail className='text-4xl mr-2 base-yellow' /> <span className=' text-3xl'>ventas@inmobiliariayucatanparaiso.com </span>
                        </li>
                        <li className='items-center flex flex-row mt-10 w-full justify-between bg-slate-950 p-2 rounded-lg md:mt-0 md:w-auto'>
                            <FiPhoneCall className='text-4xl mr-2 base-yellow' /> <span className=' text-3xl'>999 451 8679</span>
                        </li>
                    </ul>
                    <hr className='cl hidden md:block mx-5' />
                    <div className="grid grid-cols-1 gap-12 sm:grid-cols-3 md:grid-cols-4">
                        <div className="hidden sm:block col-span-1"></div>
                        <div className="hidden sm:block col-span-1"></div>
                        <div className="col-span-1 p-5"></div>
                        <div className="col-span-1 p-5">
                            <div className="grid grid-cols-2 gap-1">
                                <Select<Currency>
                                    className='col-span-1'
                                    value={currency}
                                    onChange={changeCurrency}
                                    options={[{ value: 'MXN', option: 'MXN' }, { value: 'USD', option: 'USD' }, { value: 'EUR', option: 'EUR' }, { value: 'CAD', option: 'CAD' }]}
                                />
                                <Select<lang>
                                    className='col-span-1'
                                    value={language}
                                    onChange={setLanguage}
                                    options={[{ value: 'es', option: 'Español' }, { value: 'en', option: 'English' }, { value: 'fr', option: 'Français' }]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Navigation;



