import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles

interface ImageCarouselProps {
    images: string[];
    }
const ImageCarousel = ({images}:ImageCarouselProps) => {
  const carouselProps = {
    showThumbs: false,
    autoPlay: true,
    interval: 3000,
    infiniteLoop: true,
    showStatus: false, 
  };
  return (
    <Carousel 
      {...carouselProps}
    
    >
      {images.map((image, index) => (
        <div key={index} className="mobile-carousel-slide flex justify-center items-center">
          <img src={image} alt={`${index}`}  className=" h-screen  md:h-auto bg-cover bg-center" />
        </div>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
