import React from 'react';
import RangeSlider from './range-slider';

function Search() {
    const handleRangeChange = (newRange: number | number[]) => {
        console.log(newRange);
    };
    return (
        <section className='search-section flex flex-col justify-center items-center bg-midgray px-4 md:px-32'>
            <div className=' w-full'>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-12">
                    <div className="md:col-span-1">
                        <select
                            name="ps_status"
                            className="block w-full py-2 px-3 border border-white bg-gray-500 text-white rounded-md shadow-sm focus:outline-none focus:border-blue-400"
                        >
                            <option value="-1">Property Status</option>
                            <option value="sale">For sale</option>
                            <option value="rent">For rent</option>
                        </select>
                    </div>
                    <div className="md:col-span-1">
                        <select
                            name="ps_status"
                            className="block w-full py-2 px-3 border border-white bg-gray-500 text-white rounded-md shadow-sm focus:outline-none focus:border-blue-400"
                        >
                            <option value="-1">Property Location</option>
                            <option value="sale">For sale</option>
                            <option value="rent">For rent</option>
                        </select>
                    </div>
                    <div className=" md:col-span-1">
                        <select
                            name="ps_status"
                            className="block w-full py-2 px-3 border border-white bg-gray-500 text-white rounded-md shadow-sm focus:outline-none focus:border-blue-400"
                        >
                            <option value="-1">Property Status</option>
                            <option value="sale">For sale</option>
                            <option value="rent">For rent</option>
                        </select>
                    </div>
                    <div className=" md:col-span-1">
                        <select
                            name="ps_status"
                            className="block w-full py-2 px-3 border border-white bg-gray-500 text-white rounded-md shadow-sm focus:outline-none focus:border-blue-400"
                        >
                            <option value="-1">Property Status</option>
                            <option value="sale">For sale</option>
                            <option value="rent">For rent</option>
                        </select>
                    </div>
                    <div className=" md:col-span-1">
                        <select
                            name="ps_status"
                            className="block w-full py-2 px-3 border border-white bg-gray-500 text-white rounded-md shadow-sm focus:outline-none focus:border-blue-400"
                        >
                            <option value="-1">Property Status</option>
                            <option value="sale">For sale</option>
                            <option value="rent">For rent</option>
                        </select>
                    </div>
                    <div className=" md:col-span-1">
                        <select
                            name="ps_status"
                            className="block w-full py-2 px-3 border border-white bg-gray-500 text-white rounded-md shadow-sm focus:outline-none focus:border-blue-400"
                        >
                            <option value="-1">Property Status</option>
                            <option value="sale">For sale</option>
                            <option value="rent">For rent</option>
                        </select>
                    </div>
                    <div className=" md:col-span-1 pt-5">
                        <RangeSlider initialValue={[0, 1000]} onRangeChange={handleRangeChange} maxVal={1000} />
                    </div>
                    <div className=" md:col-span-1 pt-5">
                        <RangeSlider initialValue={[0, 1000]} onRangeChange={handleRangeChange} maxVal={1000} />
                    </div>
                    <div className=" md:col-span-1 pt-5 flex justify-start md:justify-end">
                        <button className='bg-base-yellow text-white py-2 px-4 rounded-lg bg-orange-500 text-3xl'>Search</button>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Search;