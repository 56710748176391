
interface SelectProps<T> {
    value: T;
    onChange: (value: T) => void;
    options: { value: T, option: string }[];
    className?: string;
}
export default function Select<T>(
    { value, onChange, options, className }: SelectProps<T>
) {
    return (
        <select
            value={value as unknown as string}
            onChange={(e) => onChange(e.target.value as unknown as T)}
            name="ps_status"
            className={`block w-full py-2 px-3 border border-white bg-orange-400 text-white rounded-md shadow-sm focus:outline-none focus:border-blue-400 ${className}`}
        >
            {
                options.map((option) => (
                    <option key={option.value as unknown as string} value={option.value as unknown as string}>
                        {option.option}
                    </option>
                ))
            }
        </select>
    )
}