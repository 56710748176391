// useLanguage.js
import { useEffect } from 'react';
import { lang } from '../types/language';
import { useStoreActions, useStoreState } from '../state/store';

export const useLanguage = () => {
  const language = useStoreState((state) => state.language);
  const setLanguage = useStoreActions((actions) => actions.setLanguage);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('preferredLanguage');
    if (storedLanguage) {
      setLanguage(storedLanguage as lang);
    }
  }, [setLanguage]);

  const updateLanguage = (newLanguage: lang) => {
    setLanguage(newLanguage);
    localStorage.setItem('preferredLanguage', newLanguage);
  };

  return { language, updateLanguage };
};
