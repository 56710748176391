import React from 'react';
import { Helmet } from 'react-helmet-async';
function HelmetCard({ title, description, image, url, keywords }: { title: string, description: string, image: string, url: string, keywords?: string }) {
  return (
    <Helmet>
      {/* Open Graph Protocol (OGP) meta tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default HelmetCard;
