import HelmetCard from "./helmet-card"

export default function SEO ({
  title,
  description,
  image,
  url,
  keywords
}:
{
  title: string,
  description: string,
  image: string,
  url: string,
  keywords: string

}){
  return(
    <HelmetCard
      title={title}
      description={description}
      image={image}
      url={url}
      keywords={keywords}
    />
  )
}
