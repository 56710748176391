import { gql, ApolloClient, InMemoryCache } from '@apollo/client';

const url = process.env.REACT_APP_GRAPHQL_ENDPOINT as string;
const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
};

export const client = new ApolloClient({
    uri: url,
    cache: new InMemoryCache(),
    headers,
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
        },
    },
});


export const PROPERTIES_QUERY = gql`
query{
  propertiesCollection{
   items{
     title
     titleEs
     titleEn
     titleFr
     descriptionEs
     descriptionEn
     descriptionFr
     highlightsEs
     highlightsEn
     highlightsFr
     propertyTypeEs
     propertyTypeEn
     propertyTypeFr
     price
     description
     postedDate
     location {
       lat
       lon
     }
     bedroom
     bathroom
     squareMeters
     highlights
     propertyType
     mediaCollection{
       items{
         url
        
       }
     }
   }
 }
 }
   `;