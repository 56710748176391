import { createStore, action, Action, createTypedHooks } from 'easy-peasy';
import { lang } from '../types/language';
import { Currency } from '../types/currency';


interface StoreModel {
    language: lang;
    setLanguage: Action<StoreModel, lang>;
    currency: Currency;
    setCurrency: Action<StoreModel, Currency>;
}

const store = createStore<StoreModel>({
    language: 'es',
    setLanguage: action((state, payload) => {
        state.language = payload;
        localStorage.setItem('language', payload);
    }),
    currency: "MXN",
    setCurrency: action((state, payload) => {
        state.currency = payload;
        localStorage.setItem('currency', payload);
    })
})


const { useStoreActions, useStoreState } = createTypedHooks<StoreModel>();
export { useStoreActions, useStoreState };
export default store;