import React from 'react';
import { useProperties } from '../graphql/hooks';
import { PropertyCard } from './property';
import { lang } from '../types/language';
interface Props {
    language: lang;
}
function PropertiesSection({ language }: Props) {
    const { data, loading, error } = useProperties();

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error</p>;
    
    const featureProperty = data && data.propertiesCollection.items[0];
    const properties = data && data.propertiesCollection.items;

    return (
        <>
            <section className='properties-section bg-white p-5 md:px-14'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                    {
                        featureProperty && <PropertyCard property={featureProperty} expandableCard={true} language={language} />
                    }
                </div>
            </section>
            <section className='search-display-section p-5 md:px-14'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                {
                    properties && properties.map((property, index) => (
                        <PropertyCard property={property} key={index} language={language} />
                    ))
                }
            </div>
            </section>
        </>
    )
};

export default PropertiesSection;
