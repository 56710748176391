import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface RangeSliderProps {
    initialValue: [number, number];
    onRangeChange: (newRange: [number, number]) => void;
    minVal?: number;
    maxVal?: number;
}

const RangeSlider: React.FC<RangeSliderProps> = ({ initialValue, onRangeChange, minVal, maxVal }) => {
    const [range, setRange] = useState<[number, number]>(initialValue);

    const handleRangeChange = (newRange: number | number[]) => {
        if (Array.isArray(newRange)) {
            setRange(prevState => newRange as [number, number]);
            onRangeChange(newRange as [number, number]);
        }
    };

    return (
        <div>
            <div className='flex justify-between text-white'>
                <span>Area</span>
                    <span className=''>{range[0]} m&sup2;</span>
                    <span>{range[1]} m&sup2;</span>
            </div>
            <Slider
                min={minVal || 0}
                max={maxVal || 1000}
                value={range}
                onChange={handleRangeChange}
                range
            />
        </div>
    );
};

export default RangeSlider;
