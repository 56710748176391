import { useMemo } from 'react';
import {useStoreState } from '../state/store';
import { Currency } from '../types/currency';


export  function useFormatNumberWithCurrency(number: number) {
    const currency = useStoreState(state => state.currency);


    const formattedCurrency = useMemo(() => {
        if (isNaN(number)) {
        return "Invalid number";
    }
    // const formattedNumber = number.toLocaleString();
    const convertedNumber = convertCurrency(number, 'MXN', currency);
    const formattedNumber = convertedNumber.toLocaleString()
    const currencySymbol = `$`;
    const _formattedCurrency = currencySymbol + formattedNumber;
    return _formattedCurrency;

    }, [number, currency])
    return formattedCurrency
}


const exchangeRates = {
    USD: 1,    // Base rate (e.g., 1 USD)
    EUR: 0.88, // 1 USD is 0.88 EUR
    GBP: 0.75, // 1 USD is 0.75 GBP
    CAD: 1.25, // 1 USD is 1.25 CAD
    MXN: 17.49 // 1 USD is 17.49 MXN
};

// Function to convert currencies
function convertCurrency(amount:number, fromCurrency:Currency, toCurrency:Currency) {
    const fromRate = exchangeRates[fromCurrency];
    const toRate = exchangeRates[toCurrency];
    if (!fromRate || !toRate) {
        throw new Error(`Currency rate not found for ${fromCurrency} or ${toCurrency}`);
    }
    const convertedAmount = amount * (toRate / fromRate);
    return parseFloat(convertedAmount.toFixed(0));
}

